<template>
  <vue-c3 :id="componentId" :handler="handler" style="height: 100%" />
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import * as CONSTANT from '~/constant';
import formatNumber from '~/util/numeral';

const sampleData = [];

export default {
  name: 'DonutChart',
  components: {
    VueC3,
  },
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => sampleData,
    },
    componentId: {
      type: String,
      required: false,
      default: () => 'sChart',
    },
    valueType: {
      type: String,
      required: false,
      default: () => '',
    },
    height: {
      type: Number,
      required: false,
      default: () => null,
    },
    width: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      handler: new Vue(),
    };
  },
  computed: {
    options() {
      const vm = this;
      return {
        color: {
          pattern: CONSTANT.COLOR_PATTERN,
        },
        data: {
          type: 'donut',
          columns: [],
        },
        legend: {
          show: false,
        },
        donut: {
          label: {
            show: false,
          },
        },
        size: {
          height: vm.height,
          width: vm.width,
        },
        tooltip: {
          format: {
            value(value, ratio) {
              return `${formatNumber(ratio * 100, 'percentage')}`;
            },
          },
        },
        axis: {
          x: {
            type: 'category',
          },
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
      };
    },
  },
  watch: {
    columns() {
      const vm = this;

      vm.render();
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
  },
  methods: {
    render() {
      const vm = this;

      vm.handler.$emit('destroy');

      vm.handler.$emit('init', vm.options);

      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: vm.columns,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.c3-tooltip-container {
  text-align: left;
  line-height: 1.1em;
}
.c3-chart-arc path {
  stroke: rgba(180, 180, 180, 0.2) !important;
}
.c3-tooltip {
  background-color: #1a1b1d;
  padding: 10px 15px;
  width: 190px;
  font-size: 11px;
  margin-top: -37px;
  border-radius: 4px;
  opacity: 0.96;
  box-shadow: none;
}
.c3-tooltip th {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 13px;
  padding: 2px 5px;
  text-align: left;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-weight: 400;
}
.c3-tooltip td {
  font-size: 11px;
  padding: 3px 6px;
  background-color: transparent;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  color: #999;
}
.c3-tooltip tr {
  border: 0px solid #ccc;
}
.c3-tooltip td.value {
  vertical-align: middle;
}
.c3 line,
.c3 path {
  stroke: rgba(180, 180, 180, 0.2);
}
.c3 text {
  fill: #7e8890;
}

.c3 svg {
  font: 11px;
  font-family: 'Source Sans Pro', sans-serif;
}
/*.c3-line {stroke: #f00 !important;}*/

.c3-line {
  stroke-width: 3px;
}

.noy .c3-axis-y .tick text {
  opacity: 0;
}
.c3-legend {
  margin-top: 20px;
}
.c3-legend-item-tile {
  border-radius: 10px;
}
.c3-shapes circle {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.9);
}

.c3 circle {
  stroke: rgba(222, 222, 222, 0);
  stroke-width: 1px;
  fill: rgba(222, 222, 222, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c3-circle._expanded_ {
  stroke: rgba(170, 170, 170, 0.7) !important;
  stroke-width: 2px;
  fill: rgba(222, 222, 222, 0) !important;
}
</style>
