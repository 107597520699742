<template>
  <div class="donut-container">
    <vue-c3 ref="donut-chart" :handler="handler" style="height: 100%"></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import * as d3 from 'd3';
import * as CONSTANT from '~/constant';
import formatNumber from '~/util/numeral';

const sampleData = [];

export default {
  name: 'DonutChart',
  components: {
    VueC3,
  },
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => sampleData,
    },
    valueType: {
      type: String,
      required: false,
      default: () => '',
    },
    legendHeaders: {
      type: Array,
      required: false,
      default: () => [['header1', 'header2']],
    },
    showLegendHeaders: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showLegendValues: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    donutSliceTextUsing: {
      type: String,
      required: false,
      default: () => 'ratio',
    },
  },
  data: () => {
    return {
      handler: new Vue(),
    };
  },
  computed: {
    options() {
      const vm = this;
      return {
        color: {
          pattern: CONSTANT.COLOR_PATTERN,
        },
        data: {
          type: 'donut',
          columns: [],
        },
        legend: {
          position: 'right',
          show: false,
        },
        donut: {
          title: '',
          label: {
            format(value, ratio, id) {
              if (vm.donutSliceTextUsing.toLowerCase() === 'label') {
                return id;
              }
              return d3.format('.1%')(ratio);
            },
          },
        },
        size: {
          height: 400,
          width: 346,
        },
        tooltip: {
          format: {
            value(value, ratio) {
              return `${formatNumber(value, vm.valueType)} (${formatNumber(
                ratio * 100,
                'percentage'
              )})`;
            },
          },
        },
        axis: {
          x: {
            type: 'category',
          },
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
      };
    },
  },
  watch: {
    columns() {
      const vm = this;

      vm.render();
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
  },
  methods: {
    render() {
      const vm = this;

      vm.handler.$emit('destroy');

      d3.select('.donut-legend').remove();

      vm.handler.$emit('init', vm.options);

      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: vm.columns,
        });
      });

      vm.handler.$emit('dispatch', (chart) => {
        const legendsSec = d3
          .select('.donut-container')
          .insert('div', '.donut-chart')
          .attr('class', 'donut-legend')
          .append('div')
          .attr('class', 'donut-legend-table-div')
          .append('table')
          .selectAll('tr');

        if (vm.showLegendHeaders) {
          legendsSec
            .data(vm.legendHeaders)
            .enter()
            .append('tr')
            .html(function(data) {
              let str = `<th>${data[0]}</th>`;

              if (vm.showLegendValues) {
                str += `<th>${data[1]}</th>`;
              }

              return str;
            });
        }

        legendsSec
          .data(vm.columns)
          .enter()
          .append('tr')
          .attr('donut-data-id', function(id) {
            return id;
          })
          .html(function(data) {
            let str = `<td><div  title="${data[0]}" style="border-left-color: ${chart.color(
              data[0]
            )}">${data[0]}</div></td>`;

            if (vm.showLegendValues) {
              str += `<td class="values">${formatNumber(data[1], vm.valueType).replace(
                /\$/g,
                ''
              )}</td>`;
            }
            return str;
          })
          .on('mouseover', function(id) {
            chart.focus(id);
          })
          .on('mouseout', function() {
            chart.revert();
          })
          .on('click', function(id) {
            chart.toggle(id);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.light-theme {
  .c3-tooltip {
    background-color: #f7f7f7 !important;
    opacity: 0.9 !important;
  }
  .c3-tooltip td {
    color: #000000 !important;
    font-weight: 500 !important;
    background-color: #f7f7f7 !important;
    border-left: 0px dotted #999 !important;
  }
}
.donut-container {
  display: flex;
  position: relative;
  width: 100%;

  svg {
    width: 96%;
  }
  table {
    border-collapse: inherit !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 374px;
    min-width: 300px;
  }
  .c3 text {
    fill: #ffffff;
  }
  .donut-chart {
    width: 100%;
    height: 100%;
  }
  .vuec3-chart {
    min-width: 346px;
    width: 346px;
  }
  .donut-legend th,
  .donut-legend td.values {
    height: 1.2rem;
    padding-left: 6px;
    margin: 5px 0px;
    overflow: hidden;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 0.75rem;
    color: rgba(130, 130, 140, 0.8);
    text-align: left;
    cursor: pointer;
  }
  .donut-legend tr {
    display: flex;
    justify-content: space-between;
  }
  .donut-legend td > div {
    text-align: left;
    cursor: pointer;
    color: rgba(130, 130, 140, 0.8);
    border-left-style: solid;
    border-left-width: 12px;
    padding-left: 8px;
    margin: 5px 0px;
    font-size: 12px;
    font-family: sans-serif;
    overflow: hidden;
    line-height: 0.75rem;
    max-width: 220px;
    text-overflow: ellipsis;
  }

  .donut-legend th:first-child {
    padding-left: 19px;
  }
  .donut-legend th:last-child {
    padding-left: 20px;
  }
  .donut-legend td:last-child {
    text-align: right;
  }
  .donut-legend td {
    white-space: nowrap;
    vertical-align: middle;
  }

  .donut-legend {
    display: flex;
    width: 52%;
    padding-left: 40px;
    overflow-y: scroll;
    text-align: left;
    align-items: flex-start;
    overflow-x: hidden;
  }
  .donut-legend-table-div {
    position: relative;
    top: 3%;
    max-height: 372px;
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
  }
  .c3-chart-arc path {
    stroke: rgba(0, 0, 0, 0.1);
  }

  .c3-tooltip-container {
    // overflow: hidden;
    border-radius: 4px;
  }
  .c3-tooltip {
    background-color: transparent;
    box-shadow: none;
    opacity: 0.9;
  }
  .c3-tooltip td {
    padding: 10px;
    font-size: 11px;
    color: #fff;
    background-color: #222;
    border-left: 0px dotted #999;
  }

  .c3-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
  .c3-tooltip td.value {
    font-size: 12px;
    font-weight: 300;
    border-left: 1px dotted rgba(130, 130, 140, 0.2);
  }
  .c3-tooltip tr {
    border: 0px solid #ccc;
  }

  .ctip-perc {
    opacity: 0.6;
  }
}
</style>
